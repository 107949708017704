import { __decorate } from "tslib";
import { mainStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import Prismic from '@prismicio/client';
let DashboardAlunoKnnComponent = class DashboardAlunoKnnComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dreamHub = [];
        this.postsBlog = [];
        this.menus = [
            {
                title: 'Minha prática',
            },
            { divider: true, inset: true },
            {
                title: 'Notas',
            },
            { divider: true, inset: true },
            {
                title: this.userProfile.company.escola_id == 1 ? 'KNN At home' : 'Phenom Connect',
            },
        ];
    }
    async getContent() {
        this.$prismicKnnDream
            .query(Prismic.Predicates.at('document.type', 'oportunity'), {
            orderings: '[document.last_publication_date desc]',
            pageSize: 3,
        })
            .then((response) => {
            this.dreamHub = response.results;
        });
        this.$prismicBlogsKnn
            .query(Prismic.Predicates.at('document.type', 'post'), {
            orderings: '[document.last_publication_date desc]',
            pageSize: 2,
        })
            .then((response) => {
            this.postsBlog = response.results;
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    handlePedagogico() {
        this.$router.push('/main/pedagogico/all');
    }
    async mounted() {
        this.getContent();
    }
};
DashboardAlunoKnnComponent = __decorate([
    Component({
        components: {},
    })
], DashboardAlunoKnnComponent);
export default DashboardAlunoKnnComponent;
