import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Funcionalidades = class Funcionalidades extends Vue {
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], Funcionalidades.prototype, "propFuncionalidade", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Funcionalidades.prototype, "propMobile", void 0);
Funcionalidades = __decorate([
    Component
], Funcionalidades);
export default Funcionalidades;
