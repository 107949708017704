import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Performance = class Performance extends Vue {
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], Performance.prototype, "propPerformance", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Performance.prototype, "propMobile", void 0);
Performance = __decorate([
    Component
], Performance);
export default Performance;
