import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Performance from './Performance.vue';
import Funcionalidades from './Funcionalidades.vue';
import Treinamentos from './Treinamentos.vue';
import { userToolStore, dashboardStore, mainStore } from '@/store';
import _ from 'lodash';
let NewDashboard = class NewDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.fixedTools = [
            3,
            16,
            29,
            37,
            64,
            74,
            76,
        ];
        this.performances = [];
        this.trainings = [];
        this.funcionalidades = [
            { new: true, title: 'Digital nacional', text: 'A expertise da franchising e da agência de marketing para o uso de captação digital.' },
            { new: true, title: 'Integração bancária', text: 'Integração bancária que permite gerar cobrança pix com baixa automática no drive após o pagamento.' },
            { new: false, title: 'Emissão de Nota Fiscal eletrônica', text: 'Ganhe tempo gerando nota fiscal clicando em 1 botão' },
            { new: false, title: 'Integração com o Syngoo', text: 'Mensagens automáticas: Centralize todos os seus canais de atendimento em uma única tela.' },
            { new: false, title: 'Jornada comercial', text: `- Integração com as campanhas nacionais da ${this.getNomeEscola()} Idiomas; <br/> - Landing page para captar cupons digitais com QR nas parcerias; <br/> - Acompanhamento completo do funil de vendas comercial.` },
            { new: false, title: 'Suporte vitalício de gestão escolar e uso do sistema', text: 'A equipe drive é treinada nos procedimentos da franchising para te auxiliar em toda a jornada.' },
        ];
    }
    get tools() {
        return userToolStore.userTools.filter((t) => this.fixedTools.includes(t.tool_id));
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    getNextPerformanceUpdate(horario) {
        if (horario) {
            // @ts-ignore
            return this.formatDateTime(new Date(new Date(horario).getTime() + 15 * 60000));
        }
        // @ts-ignore
        return this.formatDateTime(new Date(new Date().getTime() + 15 * 60000));
    }
    getCurrentPerformanceUpdate(horario) {
        if (horario) {
            // @ts-ignore
            return this.formatDateTime(new Date(new Date(horario).getTime()));
        }
        // @ts-ignore
        return this.formatDateTime(new Date());
    }
    getPath(tool) {
        if (tool.group != '') {
            tool.group = tool.group.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        }
        let path = '/main/' + tool.group + tool.name.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        if (path.includes('portal')) {
            path = path.replace('portal', 'portal/');
        }
        return this.$router.push(path);
    }
    verifyLocalStorage(item) {
        let retorno = true;
        if (!item || item == 'undefined') {
            return false;
        }
        const listaTrainings = JSON.parse(item);
        if (listaTrainings.length <= 0) {
            return false;
        }
        _.forEach(listaTrainings, (el) => {
            if (!el.data_coleta) {
                retorno = false;
            }
            // verificar se a ultima data de obtenção dos treinamentos já se passou 15 minutos, pois os avatares tem tempo de expiração
            const data_coleta = new Date(new Date(el.data_coleta).getTime() + (14 * 60000));
            const limite_bucket = new Date(new Date().getTime() + (3 * 60 * 60000));
            if (data_coleta < limite_bucket) {
                retorno = false;
            }
        });
        return retorno;
    }
    async mounted() {
        this.performances = await dashboardStore.getDashboadData();
        const keyData = `key-treinamentos-${this.userProfile.company.id}`;
        const today = new Date();
        const key = `${keyData}-${today.toISOString().split('T')[0]}-${today.getHours()}`;
        const item = localStorage.getItem(key);
        let carregarTraings = true;
        if (this.verifyLocalStorage(item)) {
            this.trainings = JSON.parse(item);
            carregarTraings = false;
        }
        if (carregarTraings) {
            for (let i = 0; i < localStorage.length; i++) {
                const keyStorage = localStorage.key(i);
                if (keyStorage.startsWith(keyData)) {
                    localStorage.removeItem(keyStorage);
                    i--; // Ajustar o índice devido à remoção de um item
                }
            }
            this.trainings = await dashboardStore.getDashboardMainPageTreinamentos();
            localStorage.setItem(key, JSON.stringify(this.trainings));
        }
    }
};
NewDashboard = __decorate([
    Component({
        components: {
            Performance,
            Funcionalidades,
            Treinamentos,
        }
    })
], NewDashboard);
export default NewDashboard;
