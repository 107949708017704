import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { mainStore } from '@/store';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
let Treinamentos = class Treinamentos extends Vue {
    redirectTraining(link) {
        link = `https://master.${mainStore.userProfile.company.escola_id === 1 ? 'knn' : 'phenom'}idiomas.com.br/main/calendario/all`;
        window.open(link, '_blank').focus();
    }
    getSchedule(start, end) {
        const date_start = new Date(start);
        const date_end = new Date(end);
        const hours_start = String(date_start.getHours()).padStart(2, '0');
        const minutes_start = String(date_start.getMinutes()).padStart(2, '0');
        const hours_end = String(date_end.getHours()).padStart(2, '0');
        const minutes_end = String(date_end.getMinutes()).padStart(2, '0');
        return `${hours_start}:${minutes_start} - ${hours_end}:${minutes_end}`;
    }
    eventDate(date) {
        const formatDate = format(new Date(date.replace(' ', 'T')), 'dd/MM/yyyy - EEEE', { locale: pt });
        return formatDate.replace('-feira', '');
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], Treinamentos.prototype, "propTraining", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Treinamentos.prototype, "propMobile", void 0);
Treinamentos = __decorate([
    Component({
        components: {
            TextExpandableComponent,
        },
    })
], Treinamentos);
export default Treinamentos;
